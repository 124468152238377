<template>
    <section class="sort-bar">
        <span>เรียงโดย</span>
        <div class="sort-option">
            <dropdown
                v-model="selectedSort"
                :noTyping="true"
                :noDelete="true"
                :classes="{ input: [ 'text-center' , 'input-option' ], icons: 'icon-option' }"
                :options="sortOption">
            </dropdown>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            selectedSort: this.defaultOption
        };
    },
    props: {
        sortOption: Array,
        defaultOption: String
    },
    watch: {
        selectedSort(value) {
            this.changeSort(value);
        }
    },
    methods: {
        changeSort(value) {
            this.$emit('changeSort', { value: value });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.sort-bar {
    background: #ddd;
    height: 34px;
    display: flex;
    align-items: center;
    padding: 0 10px;

    span {
        font-size: 13px;
        color: #444;
    }

    .sort-option {
        margin-left: 10px;
        background: #959494;
        width: 170px;
    }
}

::v-deep .form-option {
    border: none;
    height: 34px;

    .input-option {
        height: 100%;
        background: none;
        border: none;
        color: #fff;
        font-size: 14px;
    }

    .icon-option {
        color: #fff;
    }
}
</style>