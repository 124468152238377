<template>
    <section>
        <button class="mobile-menu" @click="toggleMobileMenu()">
            <span><b-icon icon="justify" class="icon"></b-icon> เมนูบัญชีของฉัน</span> <b-icon icon="caret-down" class="icon"></b-icon>
        </button>
        <div class="user-menu d-md-block" :class="{ 'd-none': !isShowMobileMenu }">
            <h1><b-icon icon="person" class="icon"></b-icon> บัญชีของฉัน</h1>

            <span>ข้อมูลส่วนตัว</span>
            <router-link :to="routerPath.MY_PROFILE" class="sub" active-class="active" title="ข้อมูลส่วนตัว">ข้อมูลส่วนตัว</router-link>
            <router-link :to="routerPath.MY_PASSWORD" class="sub" active-class="active" title="เปลี่ยนรหัสผ่าน">เปลี่ยนรหัสผ่าน</router-link>
            <router-link :to="routerPath.MY_CAR" class="sub" active-class="active" title="ข้อมูลรถยนต์">ข้อมูลรถยนต์</router-link>
            <router-link :to="routerPath.MY_PRIVACY" class="sub" active-class="active" title="ตั้งค่าความเป็นส่วนตัว">ตั้งค่าความเป็นส่วนตัว</router-link>

            <span>ประวัติการสั่งซื้อ</span>
            <router-link :to="routerPath.MY_ORDER" class="sub" active-class="active" title="คำสั่งซื้อ">คำสั่งซื้อ</router-link>
            
            <button @click="logout()">ออกจากระบบ</button>
        </div>
    </section>
</template>

<script>
import RouterPath from '@/router/path';
import UserService from '@/services/userService';

export default {
    data() {
        return {
            isShowMobileMenu: false,
            routerPath: RouterPath
        };
    },
    methods: {
        toggleMobileMenu() {
            this.isShowMobileMenu = !this.isShowMobileMenu;
        },
        async logout() {
            let loader = this.$modalLoader.render();

            await UserService.submitLogout();

            this.$router.push('/');

            UserService.clearUserToken();
            UserService.clearUsername();

            this.$root.$emit('rootSetCartIcon');

            loader.hide();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.mobile-menu {
    border: none;
    background: $lightBackground;
    border: 1px solid #d3d3c4;
    color: #000;
    width: 100%;
    padding: 8px 20px;
    display: none;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: $screenSmall) {
        display: flex;
    }

    span {
        .icon {
            margin-right: 5px;
        }
    }

    .icon {
        opacity: 0.5;
    }
}

.user-menu {
    padding: 20px;
    background: $lightBackground;

    @media only screen and (max-width: $screenLarge) {
        padding: 12px;
    }

    @media only screen and (max-width: $screenSmall) {
        background: #f7f7ec;
        padding: 20px;
        margin: 0 20px 0;
    }

    h1 {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 0;
        color: #444;

        @media only screen and (max-width: $screenLarge) {
            font-size: 24px;
        }

        .icon {
            color: #777;
            margin-right: 5px;

            @media only screen and (max-width: $screenMedium) {
                display: none;
            }
        }
    }

    a, button, span {
        font-size: 14px;
        font-weight: bold;
        color: #444;
        display: block;
        text-decoration: none;
        background: none;
        border: none;
        outline: none;
        margin: 15px 0;
        padding: 0;

        &.sub {
            font-size: 13px;
            font-weight: normal;
            padding-left: 15px;
            margin: 5px 0;

            @media only screen and (max-width: $screenLarge) {
                font-size: 16px;
            }

            @media only screen and (max-width: $screenMedium) {
                font-size: 14px;
            }
        }

        &.active {
            font-weight: bold;
            color: #0a58ca;
            text-decoration: underline;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>