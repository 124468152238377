<template>
    <div class="user">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-3">
                    <user-menu class="mb-3"></user-menu>
                </div>
                <div class="col-sm-12 col-md-9">
                    <slot name="user-profile"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UserMenu from '@/components/user/Menu';

export default {
	components: {
		UserMenu
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.user {
    padding: 15px 0;
}
</style>