<template>
    <div class="user-orders">
        <div class="filter">
            <button @click="changeFilter('all')" :class="{ 'active': filter === 'all' }">
                <b-icon icon="bag"></b-icon>
                <span>ทั้งหมด</span>
            </button>
            <button @click="changeFilter('service')" :class="{ 'active': filter === 'service' }">
                <b-icon icon="gear"></b-icon>
                <span>รายการที่ติดตั้ง</span>
            </button>
            <button @click="changeFilter('delivery')" :class="{ 'active': filter === 'delivery' }">
                <b-icon icon="truck"></b-icon>
                <span>รายการที่จัดส่ง</span>
            </button>
            <button @click="changeFilter('review')" :class="{ 'active': filter === 'review' }">
                <b-icon icon="star"></b-icon>
                <span>รอรีวิว</span>
            </button>
        </div>

        <gadget-sort-bar :sortOption="sortOption" :defaultOption="sortOption[0].id" class="mb-3" @changeSort="changeSort($event)"></gadget-sort-bar>

        <div class="loading" v-if="!isResultReady">
            <div class="item">
                <loader-content :isDisplay="true"></loader-content>
            </div>
        </div>
        
        <template v-if="isResultReady">
            <user-order-box :content="item" v-for="(item, index) in orders" :key="index" :initShowDetail="orders.length > 1 ? false : true"></user-order-box>
        </template>
    </div>
</template>

<script>
import UserService from '@/services/userService';
import GadgetSortBar from '@/components/gadget/SortBar';
import UserOrderBox from '@/components/user/OrderBox';

export default {
    components: {
        UserOrderBox,
        GadgetSortBar
    },
    data() {
        return {
            isResultReady: false,
            orders: null,
            filter: 'all',
            sortOption: [
                { id: 'date', name: 'วันที่' },
                { id: 'status', name: 'สถานะ' },
                { id: 'shop', name: 'ร้านค้า' }
            ],
            sortType: ''
        };
    },
    mounted() {
        this.loadOrder();
    },
    methods: {
        async loadOrder() {
            this.isResultReady = false;

            const param = this.setupParam();
            const result = await UserService.getOrderHistory(param);

            this.orders = result.data;
            
            this.isResultReady = true;
        },
        setupParam() {
            return {
                filter: this.filter,
                sort: this.sortType
            };
        },
        changeFilter(filter) {
            this.filter = filter;

            this.loadOrder();
        },
        changeSort(data) {
			this.sortType = data.value;

			if (this.sortType) {
				this.loadOrder();
			}
		}
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.user-orders {
    .filter {
        margin: 0 0 20px;
        display: flex;
        justify-content: center;

        button {
            width: 85px;
            font-size: 32px;
            background: none;
            border: none;
            margin: 0;
            padding: 0;
            color: #000;

            &.active {
                color: #db4e0d;
            }

            span {
                display: block;
                margin-top: 5px;
                font-size: 12px;

                @media only screen and (max-width: $screenExtraSmall) {
                    font-size: 11px;
                }
            }
        }
    }

    .loading {
        .item {
            height: 200px;
            margin-bottom: 20px;
        }
    }
}
</style>